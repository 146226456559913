import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import Image from 'gatsby-image';
import useContentfulImage from '../../utils/useContentfulImage';
import Link from '../Link';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/

const RawHTMLEmbed = ({ data }) => {
  // Dangerously sets inner HTML from a provided snippet.
  function createMarkup() {
    return { __html: data };
  }
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={createMarkup()} />;
};

const EmbedlyScript = () => {
  return (
    <Helmet>
      <script
        async
        src="//cdn.embedly.com/widgets/platform.js"
        charSet="UTF-8"
      />
    </Helmet>
  );
};

const MarkdownEmbed = ({ data }) => {
  // If a Markdown Embed block is found, we render it with ReactMarkdown.
  // This library will parse HTML as well as markdown, as the Contentful
  // markdown editor will allow HTML. Furthermore, the embedly tool drops
  // HTML in here, so we need to be able to parse that too.

  const markdown = data;
  return (
    <>
      <EmbedlyScript />
      <ReactMarkdown
        linkTarget={(url) => (url.includes('//') ? '_blank' : '')}
        // eslint-disable-next-line react/no-children-prop
        children={markdown}
        allowDangerousHtml
      />
    </>
  );
};

const EmbeddedEntry = ({ data }) => {
  // eslint-disable-next-line no-underscore-dangle
  const {
    target: { fields, sys },
  } = data;
  const {
    contentType: {
      sys: { id },
    },
  } = sys;
  const { body } = fields;

  switch (id) {
    case 'embeddedHtml':
      return <RawHTMLEmbed data={body['en-US']} />;
    case 'embeddedMarkdown':
      return <MarkdownEmbed data={body['en-US']} />;
    default:
      console.error('Unsupported Embed', id);
      return null;
  }
};

const RichTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const assetPath = node.data.target.fields.file['en-US'];
      // check for video
      const isVideo = assetPath.contentType.includes('video');
      // Check for image
      const isImage = assetPath.contentType.includes('image');

      if (isImage) {
        const asset = useContentfulImage(assetPath.url);
        return (
          <Zoom>
            <Image alt={asset.description} fluid={asset.fluid} />
          </Zoom>
        );
      } else if (isVideo) {
        return <video controls src={assetPath.url} />;
      } else {
        return null;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => EmbeddedEntry(node),
    [INLINES.HYPERLINK]: (node) => {
      return <Link href={node.data.uri}>{node?.content[0]?.value}</Link>;
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul>
        {children}
      </ul>
    ),
  },
};

const RichText = ({ source, className = '' }) => {
  return (
    <div className={`rich-text ${className}`}>
      {documentToReactComponents(source, RichTextOptions)}
    </div>
  );
};
RichText.propTypes = {
  source: PropTypes.object.isRequired,
};

export default RichText;
