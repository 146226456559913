import React from 'react';
import { withPrefix, graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const SiteMeta = (props) => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const metaDesc = props?.description ? props.description : description;
  return (
    <Helmet>
      <title>{props?.title ? props.title : title}</title>
      <meta name="google-site-verification" content="D_NciX0TA9oOQBcI0zbG_UaHaf0CtQnGBT4LYK6qWVg" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="description" content={metaDesc} />
      <meta property="og:title" content={props?.title ? props.title : title} />
      <meta property="og:description" content={metaDesc} />
      <meta http-equiv="content-language" content="en-us" />
      <meta property="og:image" content={withPrefix('wf-open-graph.jpg')} />
      <link rel="icon" type="image/png" href={withPrefix('icon.png')} />
      {props.children}
    </Helmet>
  );
};

export default SiteMeta;
